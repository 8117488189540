<template>
  <div>
    <div class="p-b-10">
      <Row>

        <i-col span="24" class="text-right" >
          <a class="m-r-5"  @click="onExport">导出详情</a>
          <Button type="success" size="small" @click="confirmSettlement" v-if="billDetail.billStatus===0"
            >确认结算</Button
          ></i-col
        >
      </Row>
      <div class="workplatform-title">
        {{ billDetail.settlementCompanyName || " - " }}({{ billDetail.code }})
      </div>

      <Row class="m-b-5 m-t-10">
        <i-col span="8">
          <span class="title">结算周期 </span>{{ billDetail.startDate }}至{{
            billDetail.endDate
          }}
        </i-col>
        <i-col span="8">
          <span class="title">创建时间 </span>{{ billDetail.createTime }}
        </i-col>
        <i-col span="8">
          <span class="title">状态 </span>{{ billDetail.billStatusName }}
        </i-col>
      </Row>
      <Row class="m-b-5">
        <i-col span="24">
          <span class="title">结算金额 </span
          ><span class="money">{{ formatMoney(billDetail.amount) }}</span>
        </i-col>
      </Row>

      <Row class="m-b-5" v-if="billDetail.mark">
        <i-col span="24">
          <span class="title"> 备注: </span>
          {{ billDetail.mark || "-" }}
        </i-col>
      </Row>
    </div>
    <div class="p-t-10">
      <div class="workplatform-title">详情</div>
      <Tabs>
        <TabPane label="明细">
          <template>
            <Row class="m-b-5">
              <i-col span="16">
                <Row :gutter="8">
                  <!-- <i-col span="4">
                    <DatePicker
                      placeholder="入账开始结束时间"
                      size="small"
                      v-model="dateRage"
                      type="daterange"
                      split-panels
                      style="width: 100%"
                      :editable="false"
                      clearable
                      @on-change="onChangeDate"
                    ></DatePicker>
                  </i-col> -->

                  <i-col span="4">
                    <Select
                      v-model="query.incomeType"
                      clearable
                      size="small"
                      placeholder="收支类型"
                    >
                      <Option
                        v-for="item in lifeIncomeTypeArray"
                        :key="'incomeType_' + item.id"
                        :value="item.id"
                        >{{ item.name }}</Option
                      >
                    </Select>
                  </i-col>

                  <i-col span="4">
                    <Select
                      v-model="query.feeItemType"
                      clearable
                      filterable
                      size="small"
                      placeholder="服务类型"
                    >
                      <Option
                        v-for="item in lifeFeeItemTypeArray"
                        :key="'feeItem_' + item.id"
                        :value="item.id"
                        >{{ item.name }}</Option
                      >
                    </Select>
                  </i-col>
                  <!-- <i-col span="4">
                    <Select
                      v-model="query.settlementCompanyId"
                      clearable
                      filterable
                      size="small"
                      placeholder="结算主体"
                    >
                      <Option
                        v-for="item in companyArray"
                        :key="'selCom_' + item.settlementCompanyId"
                        :value="item.settlementCompanyId"
                        >{{ item.settlementCompanyName }}</Option
                      >
                    </Select>
                  </i-col> -->
                  <i-col span="12">
                    <i-input
                      v-model="query.keyWord"
                      size="small"
                      placeholder="关键字（名称、业务单号）"
                    ></i-input>
                  </i-col>
                  <i-col span="4">
                    <i-button
                      size="small"
                      class="m-r-5"
                      type="primary"
                      icon="ios-search"
                      @click="searchfeeitems"
                      >搜索</i-button
                    >
                  </i-col>
                </Row>
              </i-col>
            </Row>
            <Row :gutter="8" >
        <i-col span="24">
          <div
            v-for="(item, index) in listContentCategory"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked( item.serialNumber)"
            :class="
              checkContentCategorys.includes(item.serialNumber)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.moduleName }}
          </div>
        </i-col>
      </Row>
            <Table
              stripe
              class="m-t-5"
              :max-height="500"
              :data="feeitempage"
              :columns="tableColumns"
            ></Table>
            <div class="paging_style">
              <Page
                size="small"
                :total="feeitemTotal"
                :page-size="query.pageSize"
                show-total
                show-elevator
                show-sizer
                :page-size-opts="[15, 50, 100, 200, 500]"
                @on-page-size-change="handlePageSizeChanged"
                :current="query.pageNumber"
                @on-change="feeitemChangePage"
              ></Page>
            </div>
          </template>
        </TabPane>
        <TabPane label="上传凭证">
          <template>
            <Row :gutter="16">
              <i-col :span="billDetail.billStatus===5?'24':'18'">
                <Table
              stripe
              class="m-t-5"
              :max-height="500"
              :data="uploadFiles"
              :columns="fileColumns"
            ></Table>
              </i-col>
              <i-col span="6" v-if="billDetail.billStatus!==5">
                <Upload
              ref="upload"
              :with-credentials="true"
              :action="action"
              :headers="uploadHeaders"
              :data="data"
              :show-upload-list="false"
              :format="format"
              :max-size="maxSize"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleExceedMaxsize"
              multiple
              :on-success="handleFileUploadSuccess"
              type="drag"
            >
              <div style="padding: 20px 0">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p>点击或拖拽文件到这里进行上传</p>
              </div>
              <!-- <slot name="upload_btn">
        <div>
          <a >添加文件并上传</a>
        </div>
      </slot> -->
            </Upload>
            <!-- 上传描述，备注信息 -->
            <div class="tips m-l-10">
              <ul>
                <li>您只能上传后缀名为 {{ format.toString() }} 格式的文件</li>
                <li>文件大小不能超过 {{ maxSize / 1024 }} MB</li>
              </ul>
            </div>

              </i-col>
            </Row>

          </template>
        </TabPane>
      </Tabs>
    </div>

  </div>
</template>

<script>
import { toMoney, toNumber } from '@/utils/wnumb_own'

import { getStorage } from '@/utils/storage'
import { downloadFileRequest } from '@/utils/download'

import { listLifeBillDetail, listLifeIncomeType, listLifeFeeItemType, getModuleList, confirmBill, getBillDetail } from '@/api/statement/lifebill'
import { getFileList, deleteFiles } from '@/api/statement/lifebillFile'

export default {
  components: {
  },
  props: {},
  data () {
    return {
      loading: false,
      billDetail: {},
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx'],

      feeitempage: [],
      feeitemType: 1,

      modalContract: false,
      contractId: 0,

      lifeIncomeTypeArray: [],
      lifeFeeItemTypeArray: [],

      listContentCategory: [],
      checkContentCategorys: [1],
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyWord: ''
      },
      feeitemTotal: 0,
      tableColumns: [],

      allowEdit: false, // 允许编辑
      allowConfirm: false, // 允许确认,
      submitloading: false,

      showHistoryModal: false,
      chkContractId: 0,

      data: { billId: 0 },
      uploadList: [],
      action: process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/lifeBillAttach/uploadFile',
      visible: false,
      uploadHeaders: {
        'ooh-auth': getStorage('ooh-token')
      },
      format: ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx'],
      maxSize: 204800,
      uploadFiles: [],
      fileColumns: [
        { title: '名称', key: 'fileName', minWidth: 60, align: 'center' },
        { title: '类型', key: 'mimeType', minWidth: 60, align: 'center' },
        { title: '上传时间', key: 'createTime', minWidth: 60, align: 'center' },
        { title: '上传人', key: 'userName', minWidth: 60, align: 'center' },
        {
          title: '操作',
          key: 'operate',
          minWidth: 60,
          align: 'center',
          render: (h, data) => {
            let preview

            return h('div', [
              preview, h('a', {
                style: { 'margin-left': '5px' },
                on: {
                  click: () => {
                    this.handleDownload(data.row)
                  }
                }
              }, '下载'),

              h('a', {
                style: {
                  color: '#ef4f4f',
                  'margin-left': '5px'
                },
                on: {
                  click: () => {
                    this.handleRemove(data.row)
                  }
                }
              }, '删除')
            ])
          }
        }

      ]
    }
  },
  created () {
    this.reloadDetail()
    this.initModuleList()
    this.getLifeFeeItemTypeData()
    this.getLifeIncomeTypeData()
    this.query.billId = this.billId
    this.data.billId = this.billId
  },
  computed: {
    billId () {
      return this.$store.state.locallifesettlement.billId
    },
    beginUpdate () {
      return this.$store.state.locallifesettlement.beginUpdate
    },
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  },
  methods: {
    reloadDetail () {
      getBillDetail({ billId: this.billId }).then(res => {
        this.billDetail = res
        this.searchfeeitems()
        this.reloadFiles()
      })
    },

    /**
     * 获取收支类型
     */
    getLifeIncomeTypeData () {
      this.lifeIncomeTypeArray = []
      listLifeIncomeType({ }).then(res => {
        if (res && !res.errcode) {
          this.lifeIncomeTypeArray = res
        }
      })
    },
    /**
     * 获取费用类型
     */
    getLifeFeeItemTypeData () {
      this.lifeFeeItemTypeArray = []
      listLifeFeeItemType({ }).then(res => {
        if (res && !res.errcode) {
          this.lifeFeeItemTypeArray = res
        }
      })
    },

    // 计费项分页变更
    feeitemChangePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initbillList()
    },
    // 计费项页大小变更
    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initbillList()
    },

    formatMoney (number) {
      return toMoney(number)
    },

    formartNumber (value) {
      return toNumber(value)
    },

    // 搜索计费项
    searchfeeitems () {
      this.query.pageNumber = 1
      this.feeitempage = []
      this.initbillList()
    },
    // 加载所有结算项(分页)
    initbillList () {
      const that = this
      // that.query.feeitemType = that.feeitemType
      listLifeBillDetail(that.query).then((res) => {
        that.feeitempage = res.list
        that.feeitemTotal = res.totalRow
      })
    },

    /**
     * 结算单详情显示字段分类接口
     */
    initModuleList () {
      getModuleList({ }).then(res => {
        if (res && !res.errcode) {
          this.listContentCategory = res

          this.initColumns()
        }
      })
    },

    initColumns () {
      const that = this
      that.tableColumns = []
      const tableColumnArray = {

        feeItemTypeName: { title: '类型', key: 'feeItemTypeName', minWidth: 60, align: 'center' },
        settlementCompanyName: { title: '结算主体', key: 'settlementCompanyName', minWidth: 60, align: 'center' },
        chargeTime: { title: '入账时间', key: 'chargeTime', minWidth: 60, align: 'center' },
        goodNo: { title: '业务单号', key: 'goodNo', minWidth: 60, align: 'center' },
        goodName: { title: '名称(商品、媒体)', key: 'goodName', minWidth: 60, align: 'center' },
        incomeTypeName: { title: '收支类型', key: 'incomeTypeName', minWidth: 60, align: 'center' },
        price: {
          title: '单价(元)',
          key: 'price',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.price)
            )
          }
        },
        quantity: {
          title: '数量',
          key: 'quantity',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h('span', {}, toNumber(row.quantity))
          }
        },
        verifyAmount: {
          title: '完成金额(元)',
          key: 'verifyAmount',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.verifyAmount)
            )
          }
        },
        chargeModeDesc: { title: '计价方式', key: 'chargeModeDesc', minWidth: 60, align: 'center' },
        actualAmount: {
          title: '金额(元)',
          key: 'actualAmount',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.actualAmount)
            )
          }
        },

        // 订单
        orderStatusName: { title: '订单状态', key: 'orderStatusName', minWidth: 60, align: 'center' },
        orderTypeName: { title: '订单类型', key: 'orderTypeName', minWidth: 60, align: 'center' },
        expressTypeName: { title: '订单配送方式', key: 'expressTypeName', minWidth: 60, align: 'center' },
        orderCreateTime: { title: '订单创建时间', key: 'orderCreateTime', minWidth: 60, align: 'center' },
        orderPayTime: { title: '订单付款时间', key: 'orderPayTime', minWidth: 60, align: 'center' },
        orderSuccessTime: { title: '交易成功时间', key: 'orderSuccessTime', minWidth: 60, align: 'center' },

        // 支付信息
        payment: {
          title: '订单应收金额',
          key: 'payment',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.payment)
              )
            ])
          }
        },
        realPayment: {
          title: '订单实付金额',
          key: 'realPayment',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.realPayment)
              )
            ])
          }
        },
        postFee: {
          title: '运费',
          key: 'postFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.postFee)
              )
            ])
          }
        },
        transaction: { title: '支付流水号', key: 'transaction', minWidth: 60, align: 'center' },

        // 核销信息
        verifyStateName: {
          title: '核销状态',
          key: 'verifyStateName',
          minWidth: 60,
          align: 'center'
        },
        verifyTime: {
          title: '核销时间',
          key: 'verifyTime',
          minWidth: 60,
          align: 'center'
        },

        // 商品信息
        itemId: {
          title: '商品id',
          key: 'itemId',
          minWidth: 60,
          align: 'center'
        },
        expressStateName: {
          title: '订单商品状态',
          key: 'expressStateName',
          minWidth: 60,
          align: 'center'
        },
        itemNum: {
          title: '商品数量',
          key: 'itemNum',
          minWidth: 60,
          align: 'center'
        },
        discountPrice: { title: '商品单价', key: 'discountPrice', minWidth: 60, align: 'center' },
        itemActualAmount: { title: '商品实际成交金额', key: 'itemActualAmount', minWidth: 60, align: 'center' },

        // 收货信息
        receiverName: {
          title: '收货人/提货人',
          key: 'receiverName',
          minWidth: 60,
          align: 'center'
        },

        receiverPhone: {
          title: '收货人/提货人手机号',
          key: 'receiverPhone',
          minWidth: 60,
          align: 'center'
        },
        receiverProvince: {
          title: '收货人省份',
          key: 'receiverProvince',
          minWidth: 60,
          align: 'center'
        },
        receiverCity: {
          title: '收货人城市',
          key: 'receiverCity',
          minWidth: 60,
          align: 'center'
        },
        receiverDetailAddress: {
          title: '收货人详细',
          key: 'receiverDetailAddress',
          minWidth: 60,
          align: 'center'
        },

        // 商品退款
        refundFee: {
          title: '商品已退款金额',
          key: 'refundFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.receivedAmount)
              )
            ])
          }
        },
        itemRefundStatus: { title: '商品退款状态', key: 'itemRefundStatus', minWidth: 60, align: 'center' },

        // 合同信息
        contractTypeName: { title: '合同类型', key: 'contractTypeName', minWidth: 60, align: 'center' },
        groundCompanyName: { title: '地推', key: 'groundCompanyName', minWidth: 60, align: 'center' },
        businessName: { title: '商家名称', key: 'businessName', minWidth: 60, align: 'center' },
        productUnit: { title: '产品单位', key: 'productUnit', minWidth: 60, align: 'center' }
      }

      if (!that.checkContentCategorys.length) {
        that.checkContentCategorys = [1]
      }

      that.checkContentCategorys.sort((a, b) => (a - b)).forEach(item => {
        const category = that.listContentCategory.filter(x => parseInt(x.serialNumber) === parseInt(item))[0]
        if (category && category.fieldList.length) {
          category.fieldList.forEach(row => {
            // 验证当前全部列是否和数据库对应
            if (tableColumnArray[row.field]) {
              that.tableColumns.push(tableColumnArray[row.field])
            }
          })
        }
      })

      // this.tableColumns.push(tableColumnArray.operate)
    },
    // 变更多选框选择
    changeChecked (value) {
      const that = this
      if (that.checkContentCategorys.includes(value)) {
        const index1 = that.checkContentCategorys.indexOf(value)
        if (index1 > -1) {
          that.checkContentCategorys.splice(index1, 1)
        }
      } else {
        that.checkContentCategorys.push(value)
      }
      that.initColumns()
    },

    // 确认执行额
    confirmSettlement () {
      this.$Modal.confirm({
        title: '确认结算',
        content: '<p>是否确认此结算单信息正确？</p>',
        onOk: () => {
          this.submitloading = true
          confirmBill({ billId: this.billId }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '操作成功！' })

              this.$store.commit('set_settlement_update', new Date())
            }
            this.reloadDetail()
            this.submitloading = false
          })
        }
      })
    },

    handleExceedMaxsize (file) {
      this.$Notice.warning({
        title: '已经超过了文件大小限制',
        desc: '文件  ' + file.name + ' 过大，不能超过' + this.maxSize + 'Kb'
      })
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件类型不正确',
        desc: '文件  ' + file.name + ' 的类型只能是' + this.format.toString()
      })
    },
    handleFileUploadSuccess (response, file) {
      this.reloadFiles()
    },
    reloadFiles () {
      const that = this
      getFileList({ billId: that.billId }).then(res => {
        this.uploadFiles = res
      })
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/lifeBillAttach/downloadFile', { fileId: file.id }, file.fileName)
    },
    handleRemove (file) {
      const that = this
      that.$Modal.confirm({
        title: '操作提醒',
        content: '您将删除该凭证附件，点击确认后将删除',
        onOk: () => {
          deleteFiles({ fileIds: JSON.stringify([file.id]) }).then(res => {
            that.reloadFiles()
          })
        }

      })
    },

    onExport () {
      const postData = {
        selectColumns: JSON.stringify(this.checkContentCategorys),
        billId: this.billId
      }
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/lifeBill/exportLifeBillDetail', postData, '结算账单详情（' + (this.billDetail.code || this.billId) + '）.xlsx')
    }

  },
  watch: {
    beginUpdate (val) {
      this.searchfeeitems()
    }
  }
}
</script>
